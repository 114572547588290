<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">固废资源化技术</h1>
      </div>
    </div>
    <el-row>
      <b-card class="col-ms-12 filter" bg-variant="light">
        <b-form-group
            label="固废来源"
            label-cols-sm="4"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.sources"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryWasteBigSelect"
          >
            <el-option
                v-for="item in options.wasteSource"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="固废大类"
            label-cols-sm="4"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.wasteBigTypes"
              multiple
              collapse-tags
              placeholder="请选择"
              @change="queryWasteSmallSelect"
          >
            <el-option
                v-for="item in options.wasteBigType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="固废小类"
            label-cols-sm="4"
            label-align-sm="right"
        >
          <el-select
              v-model="searchModel.wasteSmallTypes"
              multiple
              collapse-tags
              placeholder="请选择"
          >
            <el-option
                v-for="item in options.wasteSmallType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
            label="模糊查询"
            label-cols-sm="5"
            label-align-sm="right"
        >
          <el-input
              placeholder="请输入内容"
              v-model="searchModel.searchValue"
              clearable>
          </el-input>
        </b-form-group>
        <div class="search el-col-md-24">
          <el-button type="primary" @click="selectData">查 询</el-button>
        </div>
      </b-card>

      <b-card
          class="col-ms-12 search-data"
          header="查询结果"
      >
        <el-table
            :data="table.tableData"
            style="width: 100%"
            max-height="350">
          <el-table-column
              fixed
              prop="wasteName"
              label="固废类型"
              width="200">
          </el-table-column>
          <el-table-column
              fixed
              prop="techName"
              label="资源化技术名称"
              width="200">
          </el-table-column>
          <el-table-column
              fixed
              prop="indTotal"
              label="综合影响"
              width="100">
          </el-table-column>
          <el-table-column
              fixed
              prop="indResTotal"
              label="资源影响"
              width="100">
          </el-table-column>
          <el-table-column
              fixed
              prop="indEnvironTotal"
              label="环境影响"
              width="100">
          </el-table-column>
          <el-table-column
              fixed
              prop="indEconomyTotal"
              label="经济影响"
              width="100">
          </el-table-column>
          <el-table-column
              fixed
              prop="indSocietyTotal"
              label="社会影响"
              width="100">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="text"
                  size="small">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="total, prev, pager, next"
            :total="table.total"
            :page-count="table.pageCount"
            :page-size="table.pageSizes"
            :current-page="table.currentPage"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </b-card>
    </el-row>
  </div>
</template>
<script>
import ui from "../../libs/ui"
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getAreaSelect,
  getYearSelect
} from "@/api/common"
import {queryIndicatorsTableData} from "@/api/product"

export default {
  name: 'Data',
  props: {
    msg: String
  },
  data() {
    return {
      searchModel: {
        searchValue: '',
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        years: []
      },
      options: {
        wasteSource: [],
        wasteBigType: [],
        wasteSmallType: [],
        area: [],
        years: []
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.queryWasteSourceSelect()
      this.queryAreaSelect()
      this.queryYearSelect()
      // this.selectData()
    },
    queryAreaSelect() {
      getAreaSelect().then(data => {
        this.options.area = data
      })
    },
    queryYearSelect() {
      getYearSelect().then(data => {
        this.options.years = data
      })
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then(data => {
        this.options.wasteSource = data
      })
    },
    queryWasteBigSelect() {
      // debugger
      if (this.searchModel.sources.length === 0) return
      getWasteBigTypeSelect(this.searchModel.sources).then(data => {
        this.options.wasteBigType = data
      })
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) return
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
        this.options.wasteSmallType = data
      })
    },
    queryTableData(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel
      }
      queryIndicatorsTableData(searchParams, page).then(data => {
        this.table.tableData = data.records
        this.table.total = data.total
        this.table.currentPage = data.current
        this.table.pageSizes = data.size
        this.table.pageCount = data.pages
      })
    },
    selectData() {
      this.queryTableData()
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes
      }
      this.queryTableData(this.searchModel, page)
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === '') {
        ui.toast(this, ui.variant.primary, '请输入搜索内容')
        return;
      }
      if (this.$store.getters.token === '') {
        ui.toast(this, ui.variant.primary, '请先登录账号')
        return;
      }
      this.$router.push('/search/' + this.keyword)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";
</style>