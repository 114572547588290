import http from "@/libs/http"

export function queryPage(data, page) {
    return http.post('productType/getPage', data, {params: page});
}

export function getCase(data) {
    return http.post('techData/getCaseList', data);
}

export function queryIndicatorsTableData(data, page) {
    return http.post('techIndicators/getPage', data, {params: page});
}

export function compare(searchParams) {
    return new Promise((resolve) => {
        http.post('techData/compare', searchParams).then(data => {
            resolve(data)
        })
    })
}
export function compare2(searchParams) {
    return new Promise((resolve) => {
        http.post('techData/compare', searchParams).then(data => {
            resolve(data)
        })
    })
}

export function submitTechData(techForm) {
    return new Promise((resolve) => {
        http.post('techData/submitTechData', techForm).then(data => {
            resolve(data)
        })
    })
}