import http from "@/libs/http";

export function getAreaSelect() {
    return new Promise((resolve) => {
        http.get('data/getAreaSelect').then(data => {
            resolve(data)
        })
    })
}

export function getYearSelect() {
    return new Promise((resolve) => {
        http.post('wasteFeature/getYearSelect').then(data => {
            resolve(data)
        })
    })
}

export function getTechYearSelect() {
    return new Promise((resolve) => {
        http.get('techData/getYearSelect').then(data => {
            resolve(data)
        })
    })
}

export function getWasteSourceSelect() {
    return new Promise((resolve) => {
        http.get('wasteType/getSourceSelect').then(data => {
            resolve(data)
        })
    })
}

export function getWasteBigTypeSelect(wasteSource) {
    return new Promise(resolve => {
        if (wasteSource.constructor === String) {
            wasteSource = [wasteSource]
        }
        http.post('wasteType/getBigSelect', wasteSource).then(data => {
            resolve(data)
        })
    })
}

export function getWasteSmallTypeSelect(wasteBigType) {
    return new Promise(resolve => {
        if (wasteBigType.constructor === String) {
            wasteBigType = [wasteBigType]
        }
        http.post('wasteType/getSmallSelect', wasteBigType).then(data => {
            resolve(data)
        })
    })
}

export function getProductBigSelect() {
    return new Promise(resolve => {
        http.post('productType/getBigSelect').then(data => {
            resolve(data)
        })
    })
}

export function getProductSmallSelect(wasteIds, pIds) {
    return new Promise(resolve => {
        if (wasteIds.constructor === String) {
            wasteIds = [wasteIds]
        }
        if (pIds.constructor === String) {
            pIds = [pIds]
        }
        const data = {
            wasteIds: wasteIds,
            pids: pIds
        }
        http.post('productType/getSmallSelect', data).then(data => {
            resolve(data)
        })
    })
}

export function getProductTechSelect(productSmall) {
    return new Promise(resolve => {
        if (productSmall.constructor === String) {
            productSmall = [productSmall]
        }
        http.get('techData/getProductTechSelect', productSmall).then(data => {
            resolve(data)
        })
    })
}

export function getFeatureYearSelect(wasteType) {
    return new Promise((resolve) => {
        http.post('wasteFeature/getYearSelect', wasteType).then(data => {
            resolve(data)
        })
    })
}

export function getIndicators(wasteTypes) {
    return new Promise((resolve) => {
        http.post('wasteFeature/getIndicators', wasteTypes).then(data => {
            resolve(data)
        })
    })
}

export function getIndicatorsDetail(name) {
    return new Promise((resolve) => {
        http.get('techIndicators/getIndicators', {params: {name}}).then(data => {
            resolve(data)
        })
    })
}

export function getInstructions() {
    return new Promise((resolve) => {
        http.get('instructions/getMap').then(data => {
            resolve(data)
        })
    })
}